import React from 'react'
import './location.Module.scss'
import SectionTitle from 'components/ui/sectionTitle/sectionTitle'

export default function Location() {
  return (
    <div className="location">
      <div className="location__container">
        <div className="location__header">
          <SectionTitle>Locatie</SectionTitle>
          {/* <label className="location__text">
            Nu mai sta pe ganduri, hai sa ne cunoastem!
          </label> */}
        </div>

        <iframe
          className="location__map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1423.4508330520043!2d26.05308129738289!3d44.47618834110439!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b20233bb1e563b%3A0x3a3bc976dfe592d3!2sStrada%20Soveja%20111-113%2C%20Bucure%C8%99ti%20012304!5e0!3m2!1sro!2sro!4v1696500765935!5m2!1sro!2sro"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  )
}
