import React, {
  ChangeEventHandler,
  FormEvent,
  FormEventHandler,
  useState,
} from 'react'
import FormInput from '../formInput/formInput'
import FormTextArea from '../formTextArea/formTextArea'
import Button from 'components/ui/button/button'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import './contactForm.Module.scss'
import 'react-toastify/dist/ReactToastify.css'

export default function ContactForm() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL as string
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    text: '',
  })

  console.log(SERVER_URL)

  const sendMail = async () => {
    const response = await axios.post(SERVER_URL + '/contact', formData)
    console.log(response.data)
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e: FormEvent) => {
    e.preventDefault()
    console.log(formData)
    if (
      formData.firstName == '' ||
      formData.lastName == '' ||
      formData.email == '' ||
      formData.phoneNumber == '' ||
      formData.text == ''
    ) {
      return toast.error('Toate field-urile sunt obligatorii!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
    try {
      setLoading(true)
      await sendMail()
      setLoading(false)
      toast.success(
        'Multumim pentru contact! O sa iti raspundem cat mai repede',
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        },
      )
    } catch (err) {
      setLoading(false)
      console.log(err)
      return toast.error('A aparut o eroare, incearca mai tarziu!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      text: '',
    })
  }

  const onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    // eslint-disable-next-line
    e: any,
  ) => {
    const { name, value } = e.target
    console.log(name, value)

    setFormData((old) => {
      return { ...old, [name]: value }
    })
  }

  return (
    <form className="contact__form" onSubmit={onSubmit}>
      <div className="contact__row--2">
        <FormInput
          name="lastName"
          title="Nume"
          onChange={onChange}
          value={formData.lastName}
        />
        <FormInput
          name="firstName"
          value={formData.firstName}
          title="Prenume"
          onChange={onChange}
        />
      </div>
      <div className="contact__row--2">
        <FormInput
          name="email"
          title="Email"
          onChange={onChange}
          value={formData.email}
        />
        <FormInput
          name="phoneNumber"
          title="Numar telefon"
          value={formData.phoneNumber}
          onChange={onChange}
        />
      </div>
      <FormTextArea
        name="text"
        title="Detalii"
        value={formData.text}
        onChange={onChange}
      />
      <div className="contact__button">
        <Button size="large">Trimite</Button>
      </div>
      <ToastContainer />
      {loading && <div className="loading__overlay" />}
    </form>
  )
}
