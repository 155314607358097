import React from 'react'
import logoImage from 'assets/logo.png'
import './footer.Module.scss'

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer__container">
        <img src={logoImage} className="footer__logo" />
        <div className="footer__item footer__item--right">
          <label className="footer__item__title">Quick Links</label>
          <div className="footer__item__body">
            <label
              className="footer__item__link"
              onClick={() =>
                document
                  .getElementById('Home')
                  ?.scrollIntoView({ behavior: 'smooth' })
              }
            >
              Home
            </label>
            <label
              className="footer__item__link"
              onClick={() =>
                document
                  .getElementById('Contact')
                  ?.scrollIntoView({ behavior: 'smooth' })
              }
            >
              Contact
            </label>
            <label
              className="footer__item__link"
              onClick={() =>
                document
                  .getElementById('Produsul Nostru')
                  ?.scrollIntoView({ behavior: 'smooth' })
              }
            >
              Produsul Nostru
            </label>
          </div>
        </div>
        <label className="footer__copyright">© All rights reserved</label>
      </div>
    </div>
  )
}
